<header class="header sticky-top top-0">
  <div class="container-fluid px-md-5">
    <div class="header-container row align-items-center g-0">
      <div class="col-5 header-logo fs-5">
        <a
          class="text-decoration-none"
          [routerLink]="[SharedClientRouteURI.Home]"
          title="{{ 'shared.oneAccess.title' | translate }}"
          id="linkHeaderOneAccess">
          <span class="text-black font-raleway fw-bold d-md-inline-block">
            {{ 'shared.oneAccess.title' | translate }}
          </span>
        </a>
      </div>
      <div class="col-7 text-right">
        <div class="d-flex justify-content-end align-items-center">
          <!-- Language -->
          <a
            href="javascript:void(0)"
            (click)="switchLanguage()"
            class="link-primary-dark text-decoration-none d-none d-md-inline-block"
            title="{{ 'shared.header.labelLanguageEn' | translate }}"
            id="linkHeaderLanguageEn"
            *ngIf="(this.currLang$ | async) !== 'en'">
            <span class="lang-toggler-normal">{{ 'shared.header.en' | translate }}</span>
          </a>
          <span *ngIf="(this.currLang$ | async) === 'en'" class="lang-toggler-bold d-none d-md-inline-block">{{
            'shared.header.en' | translate
          }}</span>
          <span class="d-none d-md-inline-block">&nbsp;|&nbsp;</span>
          <a
            href="javascript:void(0)"
            (click)="switchLanguage()"
            class="link-primary-dark text-decoration-none d-none d-md-inline-block me-5"
            title="{{ 'shared.header.labelLanguageFr' | translate }}"
            id="linkHeaderLanguageFr"
            *ngIf="(this.currLang$ | async) !== 'fr'">
            <span class="lang-toggler-normal">{{ 'shared.header.fr' | translate }}</span>
          </a>
          <span *ngIf="(this.currLang$ | async) === 'fr'" class="lang-toggler-bold d-none d-md-inline-block me-5">{{
            'shared.header.fr' | translate
          }}</span>

          <a
            href="javascript:void(0)"
            (click)="switchLanguage()"
            class="link-primary-dark d-inline-block d-md-none me-3 lang-circle"
            title="{{ 'shared.header.labelLanguageEn' | translate }}"
            id="linkHeaderLanguageEn"
            *ngIf="(this.currLang$ | async) === 'en'">
            <span class="lang-toggler-mobile">{{ 'shared.header.fr' | translate }}</span>
          </a>
          <a
            href="javascript:void(0)"
            (click)="switchLanguage()"
            class="link-primary-dark d-inline-block d-md-none me-3 lang-circle"
            title="{{ 'shared.header.labelLanguageFr' | translate }}"
            id="linkHeaderLanguageFr"
            *ngIf="(this.currLang$ | async) === 'fr'">
            <span class="lang-toggler-mobile">{{ 'shared.header.en' | translate }}</span>
          </a>

          <ng-content select="[help]"></ng-content>

          <!-- Divider after signing-in -->
          <div *ngIf="fullMenu || !hideSignInLink" class="horizontal-divider ms-3 d-none d-md-block"></div>

          <!-- UAOS Drop down menu only after login -->
          <div
            class="d-grid gap-2 d-md-flex justify-content-md-start ms-3"
            *ngIf="fullMenu && (authStatus$ | async)?.isAuthenticated">
            <div *ngIf="(authStatus$ | async)?.uaos?.length === 1" class="btn-group">
              <button
                tabindex="0"
                title="Facility selector"
                class="btn btn-link btn-header-menu p-0 d-flex align-items-center"
                id="facility-selection"
                type="button">
                <span class="fas fa-nav-uao text-light text-center fs-2" id="icon-69">
                  <span class="path1"></span><span class="path2"></span>
                </span>
                <span class="d-none d-md-inline-block ms-2 font-open-sans fs-sm">{{
                  (currentUAOObj$ | async)?.friendName
                }}</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-end mt-1 fs-sm" id="facility-dropdown" role="menu">
                <li *ngFor="let uao of uaosWithSelected$ | async" role="menuitem">
                  {{ uao?.friendName }}
                </li>
              </ul>
            </div>

            <div *ngIf="(authStatus$ | async)?.uaos?.length !== 1" class="btn-group" dropdown>
              <button
                tabindex="0"
                title="Facility selector"
                aria-controls="facility-dropdown"
                class="btn btn-link btn-header-menu p-0 dropdown-toggle d-flex align-items-center"
                dropdownToggle
                id="facility-selection"
                type="button">
                <span
                  class="fas fa-nav-uao text-light text-center fs-2"
                  [attr.aria-label]="'shared.header.facilityCollapse' | translate"
                  id="icon-69">
                  <span class="path1"></span><span class="path2"></span>
                </span>
                <span class="d-none d-md-inline-block ms-2 text-small fs-sm">{{
                  (currentUAOObj$ | async)?.friendName
                }}</span>
              </button>
              <ul
                *dropdownMenu
                aria-labelledBy="facility-selection"
                class="dropdown-menu dropdown-menu-end mt-1 fs-sm"
                id="facility-dropdown"
                role="menu">
                <li *ngFor="let uao of uaosWithSelected$ | async" role="menuitem">
                  <a
                    class="dropdown-item fs-7"
                    type="button"
                    [ngClass]="{ active: uao?.selected }"
                    (click)="uaoSelected()"
                    [attr.href]="sharedUIUtil.uaoSwitchUrl(uao)"
                    id="linkUaoSwitchUrl">
                    {{ uao?.friendName }} <span *ngIf="uao?.selected" class="fal fa-check text-center ms-2"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Dropdown menu only after login -->
          <div
            class="d-grid gap-2 d-md-flex justify-content-md-start ms-3"
            *ngIf="fullMenu && (authStatus$ | async)?.isAuthenticated">
            <div class="btn-group" dropdown>
              <button
                tabindex="0"
                title="Profile settings and sign out"
                aria-controls="user-dropdown-menu"
                class="btn btn-link btn-header-menu p-0 dropdown-toggle d-flex align-items-center"
                dropdownToggle
                id="user-settings"
                type="button">
                <span
                  class="fal fa-user-md menu-icon"
                  [attr.aria-label]="fullName + ('shared.header.profileCollapse' | translate)"></span>
                <span class="d-none d-md-inline-block ms-2 text-small fs-sm">{{ fullName }}</span>
              </button>
              <ul
                *dropdownMenu
                aria-labelledby="user-settings"
                class="dropdown-menu dropdown-menu-end mt-1 fs-sm"
                id="user-dropdown-menu"
                role="menu">
                <!--                <li role="menuitem px-3 py-4">-->
                <!--                  <a class="dropdown-item" routerLink="/user/profile">-->
                <!--                    <span class="fal fa-user text-center me-2"></span>-->
                <!--                    {{ 'shared.header.menuPersonalInfo' | translate }}-->
                <!--                  </a>-->
                <!--                </li>-->
                <!--                <li role="menuitem px-3 py-4">-->
                <!--                  <a class="dropdown-item" routerLink="/user/preference">-->
                <!--                    <span class="fal fa-cog text-center me-2"></span>-->
                <!--                    {{ 'shared.header.menuPreference' | translate }}-->
                <!--                  </a>-->
                <!--                </li>-->
                <!--                <li role="menuitem p-0">-->
                <!--                  <a class="dropdown-item" routerLink="/user/privacy">-->
                <!--                    <span class="fal fa-lock text-center me-2"></span>-->
                <!--                    {{ 'shared.header.menuPrivacy' | translate }}-->
                <!--                  </a>-->
                <!--                </li>-->
                <!--                <hr class="mx-2" />-->
                <li role="menuitem p-0">
                  <a
                    href="{{ sharedUIUtil.signOutUrl }}"
                    class="dropdown-item fs-7"
                    title="{{ 'shared.label.signOut' | translate }}">
                    <span class="fa-light fa-right-from-bracket text-center me-2"></span>
                    {{ 'shared.label.signOut' | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Sign In BUTTON -->
          <button
            *ngIf="!hideSignInLink && (authStatus$ | async)?.isAuthenticated === false"
            [routerLink]="[SharedClientRouteURI.SignIn]"
            class="btn btn-primary d-none d-md-block ms-3"
            id="btnHeaderSignIn">
            {{ 'shared.label.signIn' | translate }}
          </button>

          <button
            *ngIf="!hideSignInLink && fullMenu === false && (authStatus$ | async)?.isAuthenticated"
            (click)="redirectToSignOut()"
            class="btn btn-primary d-none d-md-inline-block ms-3"
            title="{{ 'shared.label.signOut' | translate }}"
            id="btnHeaderSignOut">
            {{ 'shared.label.signOut' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
